img {
  /* width: 480px;
    height: 720px;
     */
  /*
    width: 480px;
    height: 720px;
    object-fit: contain; */

  width: 100%;
  /* height: 720px; */
  object-fit: contain;
}

/* html, body { height: 100%; } */
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* * {
  border-style: 2px 3px solid solid #000;
} */
/*
table {
  display: flex
} */


table {
  /* width: 100%; */
  /* display: flex */

}

.row-is-clicked {
  font-weight: bold;
}

.mat-footer-row {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.error {
  color: red;
}

.success {
  color: green;
}


.itemsWarning {
  /* border: 1px do */
  border: 1px dashed red;
  border-color: red;
}

.border {
  border: 5px solid green;
  border-color: aqua;
}

.border2 {
  border: 5px solid orange;
  border-color: aqua;
}

.border3 {
  border: 5px solid orange;
  border-color: red;
}

.mat-expansion-panel-header-description {
  display: flex;
  justify-content: flex-end;
  margin-right: 0px !important;
}

.centre {
  text-align: center
}

/*
.receipt {
  display: flex; or inline-flex
  flex-direction: column;


}

 */
.reduce-width {
  width: 180px;

}

.overflow {
  /* inline-size: 150px; */
  overflow-wrap: break-word;
}

.left {
  float: left;
  padding: 0 20px 20px 0;
}


.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.del-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.example-expanded-row {
  font-weight: bold;
}


/* https://www.youtube.com/shorts/ii-lSK2_Nu4 */
* {
  /* box-sizing: border-box;
  outline: 1px solid limegreen !important; */
}

.button-container {
  display: flex;
}

.table-container {
  width: 100%;
}

.mat-table {
  /* display: block; */
  /* width: 100%; */
  /* overflow-x: auto; */
}

.merchant-name {
  width: 80px !important;
  word-wrap: break-word;
  /* border: 2px solid red; */

}

.trans-date {
  width: 40px !important;
  word-wrap: break-word;
  /* border: 2px solid red; */

}


/* td, th {
  text-align: center !important;
  vertical-align: middle !important;
}

.mat-sort-header-container {
  justify-content: center !important;
} */

/* .mat-header-cell.text-center { text-align: center; } */

/* ::ng-deep .mat-sort-header-container {
  display:flex;
  justify-content:center;
} */

.button-container {
  display: flex;
}